/* Décommenter la ligne si dessous pour activer UNISON */
// var allBreakpoints = Unison.fetch.all();


var currentURL     = window.location.href;
var pageAjax       = false;
var pageTransition = false;

/*
##         ####  ##    ##   ##    ##   
##          ##   ###   ##   ##   ##    
##          ##   ####  ##   ##  ##     
##          ##   ## ## ##   #####      
##          ##   ##  ####   ##  ##     
##          ##   ##   ###   ##   ##    
########   ####  ##    ##   ##    ##   

##     ##     ###     ##    ##   ########   ##         ########   ########   
##     ##    ## ##    ###   ##   ##     ##  ##         ##         ##     ##  
##     ##   ##   ##   ####  ##   ##     ##  ##         ##         ##     ##  
#########  ##     ##  ## ## ##   ##     ##  ##         ######     ########   
##     ##  #########  ##  ####   ##     ##  ##         ##         ##   ##    
##     ##  ##     ##  ##   ###   ##     ##  ##         ##         ##    ##   
##     ##  ##     ##  ##    ##   ########   ########   ########   ##     ##  
*/

/*	Intercepte les clics sur les balises "a" et les tags 
	contenant l’attribut "data-link".
	Suivant le réglage de la variable "pageTransition"
	traite l'action directement ou transmet l’url à la fonction
	showLoader() pour initier une animation de transition de page
*/
var linkHandler = function(element) {
	var $link = element;
	var url  = ( $link.attr('href') || $link.attr('data-link'));
	
	if ( $link.hasClass('no-link') || url.match('^#') ) {
		return;
	}

	if( $link.attr('target') == "_blank" ){
		window.open( url , "_blank");
		return;
	}

	if (url === currentURL)
		return;

	// Annule l'action du lien en cas de chargement via AJAX
	if( pageAjax && $link.attr('rel') ){
		return;
	}

	// On envoi l’URL vers le loader s'il y a un loader
	if( pageTransition ){
		showLoader( url );
		return;
	}

	// Ou on joue une simple redirection
	window.location.href = url;
};



/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

$(document).ready(function() {

	FastClick.attach(document.body);

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	/**
	 * Exemple UNISON
	 */
	// Unison.on('change', function(bp) {
	// 	switch ( bp.name ) {
	// 		case 'tablet':
	// 			mySmallBreakpointFunction();
	// 			break;
	// 		case 'desktop':
	// 			myMediumBreakpointFunction();
	// 			break;
	// 		default: 
	// 			myLargeBreakpointFunction();
	// 			break;
	// 	}
	// });


	// Transmission des liens vers la fonction linkHandler()
	$('[data-link]').click(function(e) {
		linkHandler( $(this) );
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	$('a').click(function(e) {
		if( ! $(this).hasClass('no-link') ){
			e.preventDefault();
			linkHandler( $(this) );
		}
	});

	// Questionnaire Therasomnia
	$('#term-of-use').click(function(e) {
		let $btnTherasomnia = $('#therasomnia-link');
		if($(this).is(':checked')) {
			$btnTherasomnia.removeClass('button-disabled');
		} else {
			$btnTherasomnia.addClass('button-disabled');
		}
	});
});




/*
##     ##  ########   ##    ##   ##     ##       ##     ##   #######   ########   ####  ##         ########   
###   ###  ##         ###   ##   ##     ##       ###   ###  ##     ##  ##     ##   ##   ##         ##         
#### ####  ##         ####  ##   ##     ##       #### ####  ##     ##  ##     ##   ##   ##         ##         
## ### ##  ######     ## ## ##   ##     ##       ## ### ##  ##     ##  ########    ##   ##         ######     
##     ##  ##         ##  ####   ##     ##       ##     ##  ##     ##  ##     ##   ##   ##         ##         
##     ##  ##         ##   ###   ##     ##       ##     ##  ##     ##  ##     ##   ##   ##         ##         
##     ##  ########   ##    ##    #######        ##     ##   #######   ########   ####  ########   ########   
*/

	$('#toggle').click(function() {
	   $(this).toggleClass('active');
	   $('#overlay').toggleClass('open');
	});


/*
 ######    ##     ##     ###     ########   
##    ##   ###   ###    ## ##    ##     ##  
##         #### ####   ##   ##   ##     ##  
##   ####  ## ### ##  ##     ##  ########   
##    ##   ##     ##  #########  ##         
##    ##   ##     ##  ##     ##  ##         
 ######    ##     ##  ##     ##  ##         
*/
$(document).ready(function() {

	if ( $("#map").length > 0 ){

    	let latitude = 45.768801064942416;
    	let longitude = 4.780832414441832;

		map = new GMaps({
			div: '#map',
			lat: latitude,
			lng: longitude,
			scrollwheel: false,

			styles: [
			    {"featureType": "administrative","elementType": "labels.text.fill","stylers": [{"color": "#957090"}]},
			    {"featureType": "landscape","elementType": "all",
			        "stylers": [
			            {
			                "color": "#f8f8f8"
			            }
			        ]
			    },
			    {
			        "featureType": "poi",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.attraction",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.business",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.government",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.medical",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.park",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.place_of_worship",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.school",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.sports_complex",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "road",
			        "elementType": "all",
			        "stylers": [
			            {
			                "saturation": -50
			            },
			            {
			                "lightness": 45
			            }
			        ]
			    },
			    {
			        "featureType": "road.highway",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "simplified"
			            }
			        ]
			    },
			    {
			        "featureType": "road.arterial",
			        "elementType": "labels.icon",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "road.local",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "on"
			            }
			        ]
			    },
			    {
			        "featureType": "transit",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "transit.line",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "transit.station.rail",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "on"
			            }
			        ]
			    },
			    {
			        "featureType": "water",
			        "elementType": "all",
			        "stylers": [
			            {
			                "color": "#957090"
			            },
			            {
			                "visibility": "on"
			            },
			            {
			                "lightness": 75
			            }
			        ]
			    }
			]
			
		});

		var image = new google.maps.MarkerImage('/images/pointer.png', null, null, null, new google.maps.Size(40,40));

		map.addMarker({
		  lat: latitude,
		  lng: longitude,
		  icon:image
		});

		google.maps.event.addDomListener(window, 'resize', function() {
	    	map.setCenter(latitude, longitude);
	    });
	}





	/*
########    #######   ########   ##     ##  
##         ##     ##  ##     ##  ###   ###  
##         ##     ##  ##     ##  #### ####  
######     ##     ##  ########   ## ### ##  
##         ##     ##  ##   ##    ##     ##  
##         ##     ##  ##    ##   ##     ##  
##          #######   ##     ##  ##     ##  
*/

	$("#contact-form").validationEngine({ 
		scroll: false,
		promptPosition: 'topRight:-120,0',
		autoPositionUpdate:true
	});

	$("#contact-form").submit(function(){
		if($("#contact-form").validationEngine('validate')){
			var url  = $(this).attr("action");
			var data = $(this).serialize() + '&act=envoi';
			$('.envoi-message').html('<p>Envoi en cours...</p>');
			$('.envoi-retour').hide();
			var div  = $(".envoi-content").fadeIn();

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					div.find('.envoi-message').html(retour);
					$(".envoi-retour").show().click(function(e){
						e.preventDefault();
						$('.envoi-content').fadeOut();
					});
				}
			});
		}
		return false;
	});

});